<template>
  <div>
    <b-button
      ref="btn-open-modal-change-password"
      v-b-modal="id"
      class="d-none"
    />
    <b-modal
      :id="id"
      :ref="id"
      ok-title="Đổi"
      cancel-title="Hủy"
      header-text-variant="primary"
      :title="modalMode==='admin-change-password'?'Đổi mật khẩu cho tài khoản ' + user.fullname : 'Đổi mật khẩu'"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form-group
        v-if="modalMode==='user-change-password'"
        label="Mật khẩu hiện tại"
        label-for="current_password"
      >
        <b-form-input
          id="current_password"
          v-model="currentPassword"
          placeholder="Mật khẩu hiện tại"
        />
      </b-form-group>
      <b-form-group
        label="Mật khẩu mới"
        label-for="new_password"
      >
        <b-form-input
          id="new_password"
          v-model="password"
          placeholder="Mật khẩu mới"
        />
      </b-form-group>
      <b-form-group
        v-if="modalMode==='user-change-password'"
        label="Lặp lại mật khẩu mới"
        label-for="re_password"
      >
        <b-form-input
          id="re_password"
          v-model="confirmPassword"
          placeholder="Lặp lại mật khẩu mới"
        />
      </b-form-group>
      <span
        v-if="modalErrorText"
        class="text-danger"
      >{{ modalErrorText }}</span>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: 'create',
    },
    modalMode: {
      type: String,
      default: 'user-change-password',
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    adminChangePassword() {
      const data = {
        password: this.password,
        user_id: this.user.id,
      }
      console.log('admin-change-password', data)
      return this.$action(store.dispatch('user/adminChangePassword', data), true)
    },
    changePassword() {
      const data = {
        current_password: this.currentPassword,
        password: this.password,
        confirm_password: this.confirmPassword,
      }
      console.log('user-change-password', data)
      return this.$action(store.dispatch('user/changePassword', data), true)
    },
    async handleOk(event) {
      event.preventDefault()
      try {
        if (this.modalMode === 'user-change-password') {
          await this.changePassword()
        }
        if (this.modalMode === 'admin-change-password') {
          await this.adminChangePassword()
        }
        setTimeout(() => {
          this.hideModal(this.id)
        })
      } catch (e) {
        console.log(e.message, e)
        this.modalErrorText = e.message || e
      }
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })

    const password = ref('')
    const currentPassword = ref('')
    const confirmPassword = ref('')
    const modalErrorText = ref('')

    const resetModal = () => {
      password.value = ''
      currentPassword.value = ''
      confirmPassword.value = ''
      modalErrorText.value = ''
    }

    return {
      resetModal,
      password,
      currentPassword,
      confirmPassword,
      modalErrorText,
    }
  },
}
</script>
